export const environment = {
  production: false,
  environment: 'cdt',

  //configure API
  url: 'https://wapazewtmlit007rcmeccoreapi.azurewebsites.net/api/Login/GetKeysFromJwkUriToValidateSession',
  dcrp_api_base_url: 'https://dcrp-services-cdt.maersk-digital.net',
  ccdcrp_api_base_url: 'https://fleet-dcrp-cdt.maersk-digital.net',
  workflow_api_base_url:
    'https://wapazewtmlit016workflowapi.azurewebsites.net/',
  authority: 'https://accounts-cdt.maersk.com/thirdparty',
  clientId: 'thirdparty',
  postLoginRedirectUri: 'https://ccdcrp-cdt.maersk.com/oauth2/login',
  consumer_key: 'TpZjSVg2GZDTGM8yKYQQAIFTTq0tSPeR',
  inactivityLogoutSeconds: 300, // 5 Mins
  refreshTokenBeforeSeconds: 6300, // 1 Hr & 45 Mins before token expiry
  isSiteUndermaintenance: false,
  localTemplate: true,
  enableCaseWorkflow: true,
  disableFileUpload: false,
  searchIntervalForManualCase: 90,
  searchIntervalForContainerMoves: 15,
  searchIntervalForInspections: 15,
  containerJourneyDurationInDays: 50,
  api_key_rum:
    'v74f0gLNCHGfzigDXby50QcD++qTfxgnm3aSLXDQzWRlKLUK83wzbRyXXV7cNA==',
  FAROENVIRONMENT: 'cdt',
  IN_SIGHT_FEEDBACK_ID: '4b8ba5f5-bea7-4b8b-9391-99bca83881e3',
};
